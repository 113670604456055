import React, { Suspense, lazy } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import LandingPage from 'pages/LandingPage/LandingPage';
import RouteChange from './RouteChange';
import { RootState } from 'services/state/store/configureStore';

const HomePage = lazy(() => import('pages/HomePage/HomePage'));
const MusicPage = lazy(() => import('pages/MusicPage/MusicPage'));
const PlaylistsPage = lazy(() => import('pages/PlaylistsPage/PlaylistsPage'));
const AlbumPage = lazy(() => import('pages/AlbumPage/AlbumPage'));
const ArtistPage = lazy(() => import('pages/ArtistPage/ArtistPage'));
const PlaylistPage = lazy(() => import('pages/PlaylistPage/PlaylistPage'));
const PartnerPage = lazy(() => import('pages/PartnerPage/PartnerPage'));
const PricingPage = lazy(() => import('pages/PricingPage/PricingPage'));
const TierSelectPage = lazy(
  () => import('pages/TierSelectPage/TierSelectPage')
);
const UpgradePage = lazy(() => import('pages/UpgradePage/UpgradePage'));
const FaqPage = lazy(() => import('pages/FaqPage/FaqPage'));
const UserPage = lazy(() => import('pages/UserPage/UserPage'));
const SearchPage = lazy(() => import('pages/SearchPage/SearchPage'));
const SignupPage = lazy(() => import('pages/SignupPage/SignupPage'));
const AIChatPage = lazy(() => import('pages/AISearchPage/AISearchPage'));

const SuccessPage = lazy(
  () => import('components/SignUpModal/SuccessPage/SuccessPage')
);
const VerifyPage = lazy(
  () => import('components/SignUpModal/VerifyPage/VerifyPage')
);
const ResetPage = lazy(() => import('pages/ResetPage/ResetPage'));
const ActivatePage = lazy(() => import('pages/ActivatePage/ActivatePage'));
const Four04Page = lazy(() => import('pages/Four04Page/Four04Page'));

const NewRoutes = () => {
  const { user } = useSelector((state: RootState) => state.auth);
  const location = useLocation();

  const requireAuth = (
    next: React.ReactNode,
    path?: string
  ): React.ReactNode => {
    // TODO: include different auth types for VOD or admin
    const rootPath = `/?redirect=${location.pathname}`;

    if (!user) {
      // TODO: Toast is firing on sign-out. Lame. Revisit.
      // toastr.error(`Please sign in to view that page`);
      return <Navigate to={path || rootPath} />;
    }
    return next;
  };

  return (
    <Suspense fallback={<div></div>}>
      <RouteChange />
      <Routes>
        <Route path="/" element={user ? <HomePage /> : <LandingPage />} />
        <Route path="/signup" element={<SignupPage />} />
        <Route path="/music" element={<MusicPage />} />
        <Route path="/playlists/*" element={<PlaylistsPage />} />
        <Route path="/playlist/:id" element={<PlaylistPage />} />
        <Route path="/artist/:id" element={<ArtistPage />} />
        <Route path="/creator/:id" element={<PartnerPage />} />
        <Route path="/album/:id" element={<AlbumPage />} />
        <Route path="/pricing" element={<PricingPage />} />
        <Route path="/pricing/:id" element={<TierSelectPage />} />
        <Route path="/pricing/upgrade" element={<UpgradePage />} />
        <Route path="/faq" element={<FaqPage />} />
        <Route path="/search" element={<SearchPage />} />
        <Route path="/aisearch" element={<AIChatPage />} />
        <Route path="/success" element={<SuccessPage />} />
        <Route path="/verify" element={<VerifyPage />} />
        <Route path="/reset-password" element={<ResetPage />} />
        <Route path="/activate" element={<ActivatePage />} />
        <Route path="/user/*" element={requireAuth(<UserPage />)} />
        <Route path="*" element={<Four04Page />} />
      </Routes>
    </Suspense>
  );
};

export default NewRoutes;
